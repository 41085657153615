<template>
  <body class="is-text-align-center">
    <div class="content-wrap">
      <div class="content">
        <img
          class="profile-image big rounded"
          src="../assets/profile-picture.png"
          alt="Hi there!"
        />

        <div class="content-container">
          <div v-if="isHome"><AboutMeComponent /></div>
          <div v-if="isSkills"><SkillsComponent /></div>
          <div v-if="isMusic"><MusicComponent /></div>
        </div>

        <div class="button-group">
          <SocialComponent />

          <p>
            <a
              class="button"
              @click="loadHomeComponent"
              :class="[isHome ? 'is-active' : 'not-active']"
              title="Home"
              >About me</a
            >
            <a
              class="button"
              @click="loadSkillsComponent"
              :class="[isSkills ? 'is-active' : 'not-active']"
              title="Skills"
              >Skills</a
            >
            <a
              class="button"
              @click="loadMusicComponent"
              :class="[isMusic ? 'is-active' : 'not-active']"
              title="Music"
              >Music</a
            >
          </p>
        </div>
      </div>
    </div>
  </body>
</template>

<script>
// import { defineComponent, computed, reactive } from "vue";
import { useHead } from "@vueuse/head";

import AboutMeComponent from "@/components/AboutMe.vue";
import SkillsComponent from "@/components/Skills.vue";
import MusicComponent from "@/components/Music.vue";
import SocialComponent from "@/components/Social.vue";

//import { useMeta } from "vue-meta";

export default {
  name: "app",
  components: {
    AboutMeComponent,
    SkillsComponent,
    MusicComponent,
    SocialComponent,
  },
  data() {
    return {
      isHome: true,
      isSkills: false,
      isMusic: false,
    };
  },
  methods: {
    loadHomeComponent() {
      this.isHome = true;
      this.isSkills = false;
      this.isMusic = false;
    },
    loadSkillsComponent() {
      this.isHome = false;
      this.isSkills = true;
      this.isMusic = false;
    },
    loadMusicComponent() {
      this.isHome = false;
      this.isSkills = false;
      this.isMusic = true;
    },
  },
  // mounted() {
  //   document.title = "Juris Tihomirovs - Software Enthusiast";
  // },
  setup() {
    useHead({
      //title: "Juris Tihomirovs - Software Enthusiast",
      meta: [
        {
          name: "description",
          content:
            "First, I enjoy product design, code, and things in between. I love to learn about the latest technology trends, product design, team building, and management.",
        },
        {
          name: "keywords",
          content: "Python, Django, Vue, Juris Tihomirovs, Marlēna",
        },
        {
          name: "copyright",
          content: "Juris Tihomirovs",
        },
        {
          name: "distribution",
          content: "Global",
        },
        {
          name: "rating",
          content: "general",
        },
        {
          name: "og:type",
          content: "website",
        },
        {
          name: "og:url",
          content: "https://tihomirovs.me",
        },
        {
          name: "og:title",
          content: "Juris Tihomirovs",
        },
        {
          name: "og:description",
          content: "Juris Tihomirovs - Software Enthusiast",
        },
        {
          name: "og:site_name",
          content: "Juris Tihomirovs",
        },
        {
          name: "author",
          content: "Juris Tihomirovs",
        },
      ],
    });
  },
};
</script>

<style>
.is-active {
  background-color: #eee;
}

.not-active {
  background-color: #fff;
}
</style>
