import { createApp } from "vue";
import { createHead } from "@vueuse/head";
import App from "./App.vue";
import router from "./router";
//import VueMeta from "vue-meta";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faGithubSquare } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faLinkedin, faGithubSquare, faEnvelope);

// @vueuse/head
const head = createHead();

createApp(App)
  //  .use(VueMeta)
  .use(head)
  .use(router)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
