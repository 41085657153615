<template>
  <h1>{{ slogan }}</h1>
  <!-- DESCRIPTION -->
  <p>
    {{ paragraph_1 }}
  </p>
  <p>
    {{ paragraph_2 }}
  </p>
  <p>
    {{ paragraph_3 }}
  </p>
</template>

<script>
export default {
  name: "AboutMe",
  data() {
    return {
      slogan: "Hi, there!",
      paragraph_1:
        "I would describe myself as an inventor, system analyst, musician, IT architect, thinker, project manager, developer, engineer. I’m interested in new technologies, music, guitars, audio, vinyl, sound recordings, books, 35mm photography, programming languages.",
      paragraph_2:
        "I enjoy product design, code, and things in between. I love to learn about the latest technology trends, development, product design, team building, and management.",
      paragraph_3:
        "I am looking forward to new connections, communities, new conversations, and new collaborations. Feel free to get in touch with me.",
    };
  },
};
</script>
