<template>
  <div class="wrapper">
    <div class="icon linkedin">
      <span
        ><a :href="linkedin_link" title="Check out my LinkedIn"
          ><font-awesome-icon :icon="['fab', 'linkedin']" /></a
      ></span>
    </div>

    <div class="icon github">
      <span
        ><a :href="github_link" title="Check out my GitHub"
          ><font-awesome-icon :icon="['fab', 'github-square']" /></a
      ></span>
    </div>

    <div class="icon mail">
      <span
        ><a :href="mail_link" title="Contact me via e-mail"
          ><font-awesome-icon icon="envelope" /></a
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Social",
  data() {
    return {
      linkedin_link: "https://www.linkedin.com/in/juris-tihomirovs/",
      github_link: "https://github.com/jtihomirovs/",
      mail_link: "https://aemail.com/82G5",
    };
  },
};
</script>
