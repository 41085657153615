<template>
  <h1>{{ slogan }}</h1>
  <p>
    I'm a guitarist in the indie rock band <b>Marlēna</b>. Bands Marlēna origins
    can be traced back to the end of 2015 when some creative people decided to
    apply for the music and art festival
    <a class="link" :href="bildes_link">BILDES 2015</a>. The collaboration and
    performance were quite successful, so it was decided to continue our
    creative activity. We position ourselves as an indie-rock band, while Jānis
    Žilde has described us in one of the
    <a class="link" :href="tvnet_link">"Demo skapis" articles</a>
    as a melodic rock association that fuses something from modern blues-rock
    and grunge. In 2017 we released our first studio recording
    <a class="link" :href="bandcamp_magones_link">"Pelni un magones"</a>, while
    in 2019 we recorded a new song
    <a class="link" :href="bandcamp_tik_vien_link">"Tik vien"</a>.
  </p>
  <p>
    Reach us on
    <a class="link" :href="spotify_link">Spotify!</a>
  </p>
</template>

<script>
export default {
  name: "Music",
  data() {
    return {
      slogan: "Music.",
      bildes_link: "https://www.bildes.lv/2index.php?id=5&sub=32&hist=4&new=9",
      tvnet_link:
        "https://www.tvnet.lv/6761920/demo-skapi-sonedel-marlena-tree-of-dawn-un-rons",
      bandcamp_magones_link:
        "https://marlenaband.bandcamp.com/track/pelni-un-magones",
      bandcamp_tik_vien_link: "https://marlenaband.bandcamp.com/track/tik-vien",
      spotify_link:
        "https://open.spotify.com/artist/5qCifm5hdHXI1EahMBjhpo?si=Ib8VLR5JTtqF-nMeeuX9DA",
    };
  },
};
</script>
