<template>
  <h1>{{ slogan }}</h1>
  <h3>{{ slogan_non_tech }}</h3>
  <p>
    <a class="link" :href="pm_link"
      >Project management (Waterfall, Agile, Scrum)</a
    >, System analysis,
    <a class="link" :href="togaf_link">System architecture</a>
  </p>

  <h3>{{ slogan_tech }}</h3>
  <p>
    HTML&CSS,
    <a class="link" :href="python_link">Python</a>,
    <a class="link" :href="django_link">Django</a>, Vue
  </p>

  <h3>{{ Other }}</h3>
  <p>
    Draw.io,
    <a class="link" :href="git_link">GIT</a>, Postman, Balsamiq Mockups
  </p>
</template>

<script>
export default {
  name: "Skills",
  data() {
    return {
      slogan: "Skills.",
      slogan_non_tech: "Non-Technical",
      slogan_tech: "Technical",
      slogan_other: "Other",
      pm_link:
        "https://www.credly.com/badges/30054e48-354a-4dcb-bdf9-33f659218e82/linked_in_profile",
      togaf_link:
        "https://www.credly.com/badges/b65a1672-c9b5-4e28-814a-5ba200314e59/public_url",
      python_link:
        "https://www.udemy.com/certificate/UC-5e048e3e-2f3b-422f-a26c-203024ebd16b/",
      django_link:
        "https://www.udemy.com/certificate/UC-4d5ed187-a9b0-4252-af8c-1ec922395047/",
      git_link: "https://github.com/jtihomirovs/",
    };
  },
};
</script>
