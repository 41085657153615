<template>
  <!-- router-view load router/index.js Home component -->
  <router-view />
</template>

<style>
/*
	HK Grotesk Font Free by Hanken Design Co.
	License : OFL (SIL Open Font License)
	downloaded from : https://fontlibrary.org/en/font/hk-grotesk
*/

@font-face {
  font-family: "HK Grotesk";
  src: url("fonts/hk-grotesk/hkgrotesk-light-webfont.woff2") format("woff2"),
    url("fonts/hk-grotesk/hkgrotesk-light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("fonts/hk-grotesk/hkgrotesk-lightitalic-webfont.woff2")
      format("woff2"),
    url("fonts/hk-grotesk/hkgrotesk-lightitalic-webfont.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("fonts/hk-grotesk/hkgrotesk-regular-webfont.woff2") format("woff2"),
    url("fonts/hk-grotesk/hkgrotesk-regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("fonts/hk-grotesk/hkgrotesk-italic-webfont.woff2") format("woff2"),
    url("fonts/hk-grotesk/hkgrotesk-italic-webfont.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("fonts/hk-grotesk/hkgrotesk-medium-webfont.woff2") format("woff2"),
    url("fonts/hk-grotesk/hkgrotesk-medium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("fonts/hk-grotesk/hkgrotesk-mediumitalic-webfont.woff2")
      format("woff2"),
    url("fonts/hk-grotesk/hkgrotesk-mediumitalic-webfont.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("fonts/hk-grotesk/hkgrotesk-semibold-webfont.woff2") format("woff2"),
    url("fonts/hk-grotesk/hkgrotesk-semibold-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("fonts/hk-grotesk/hkgrotesk-semibolditalic-webfont.woff2")
      format("woff2"),
    url("fonts/hk-grotesk/hkgrotesk-semibolditalic-webfont.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("fonts/hk-grotesk/hkgrotesk-bold-webfont.woff2") format("woff2"),
    url("fonts/hk-grotesk/hkgrotesk-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("fonts/hk-grotesk/hkgrotesk-bolditalic-webfont.woff2")
      format("woff2"),
    url("fonts/hk-grotesk/hkgrotesk-bolditalic-webfont.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

/*  --------------------------------------------

	1. BASE (@base) - base html elements

    -------------------------------------------- */
html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-align: center;
}
* {
  box-sizing: border-box;
}
body {
  font-family: "HK Grotesk", sans-serif;
  color: #222;
  background-color: #fff;
  font-size: 14px;
  line-height: 1.7;
  align-items: center;
}
img {
  max-width: 100%;
  height: auto;
}
::selection {
  text-shadow: none;
  color: #000;
  background: #eee;
}
::-moz-selection {
  text-shadow: none;
  color: #000;
  background: #eee;
}

/*  --------------------------------------------

	2. TYPOGRAPHY (@typo) - typographic styles

    -------------------------------------------- */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "HK Grotesk", sans-serif;
  font-weight: 700;
  font-style: normal;
  margin: 1em 0 0.66em 0;
}
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0;
}
h1 {
  font-size: 6vw;
  line-height: 1.1;
  margin-top: 0;
}
h2 {
  font-size: 1.8em;
  line-height: 1.3;
}
h3 {
  font-size: 1.25em;
  line-height: 1.3;
}
.profile-image + h1 {
  margin-top: 28px;
}
h4 {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 5px;
}
p:last-child {
  margin-bottom: 0;
}
/* LIGHT TEXT */
.is-text-light {
  color: #fff;
}
.is-text-light a {
  color: #fff;
}

/*  --------------------------------------------

	3. HELPERS (@helpers) - shorthand helper styles

    -------------------------------------------- */
.rounded {
  border-radius: 50%;
}
.soft-rounded {
  border-radius: 8px;
}
.is-text-align-center {
  text-align: center;
}
.is-text-align-right {
  text-align: right;
}
.uppercase {
  text-transform: uppercase;
}
.bold {
  font-weight: bold;
}

/*  --------------------------------------------

	2. LAYOUT (@layout) - layout styles

    -------------------------------------------- */

/* CONTENT */
.content-wrap {
  width: 80%;
  margin: 0 auto;
  max-height: 100%;
  position: relative;
  z-index: 20;
}
.content {
  padding: 3em 0;
}

/* PROFILE IMAGE */
.profile-image {
  max-height: 92px;
}
.profile-image.is-outside {
  margin-top: -50%;
  margin-bottom: 0;
}

.content-container {
  height: 530px;
}

/*  --------------------------------------------

	5. ELEMENTS (@elements) - common used elements

    -------------------------------------------- */

/* BUTTONS */
.button {
  border: 2px solid #222;
  font-family: inherit;
  font-size: 16px;
  line-height: 1;
  color: #222;
  text-decoration: none;
  background: none;
  cursor: pointer;
  padding: 16px 32px;
  margin: 10px 20px 0 0;
  display: inline-block;
  outline: none;
  position: relative;
  transition: all 0.3s;
  overflow: hidden;
}
.button:hover {
  background-color: #eee;
}

/*  --------------------------------------------

	6. MEDIA QUERIES (@media) - base html elements

    -------------------------------------------- */
/* Extra small devices (phones, 768px and down) */
@media only screen and (max-width: 768px) {
  .content-container {
    height: 510px;
  }
}

/* TABLETS */
@media screen and (min-width: 768px) {
  body {
    font-size: 19px;
  }

  .profile-image {
    max-height: 128px;
  }
  .profile-image.small {
    max-height: 92px;
  }
  .profile-image.big {
    max-height: 192px;
  }

  h1 {
    font-size: 4vw;
    line-height: 1;
  }

  .content-container {
    height: 520px;
  }
}

/* DESKTOPS */
@media screen and (min-width: 1200px) {
  .content-wrap {
    width: 50%;
  }

  /* BUTTONS */
  .button.huge {
    font-size: 20px;
    padding: 22px 40px;
  }

  .content-container {
    height: 520px;
  }
}

/* DESKTOPS HIGH RES */
@media screen and (min-width: 1600px) {
  .content-wrap {
    width: 50%;
  }

  .content {
    padding: 6em 0;
  }

  .content-container {
    height: 460px;
  }
}

/* Custom icons */
.wrapper {
  display: inline-flex;
}

.wrapper a {
  color: inherit;
}

.wrapper .icon {
  margin: 0 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
}

.wrapper .icon span {
  position: relative;
  z-index: 2;
  height: 60px;
  width: 60px;
  display: block;
  background: #fff;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  text-align: center;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  font-size: 25px;
  line-height: 60px;
}

.wrapper .icon:hover span,
.wrapp .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
}

.wrapper .icon:hover span {
  color: #fff;
}

.wrapper .linkedin:hover span,
.wrapper .linkedin:hover .tooltip,
.wrapper .linkedin:hover .tooltip:before {
  background: #0077b5;
}

.wrapper .github:hover span,
.wrapper .github:hover .tooltip,
.wrapper .github:hover .tooltip:before {
  background: #333;
}

.wrapper .mail:hover span,
.wrapper .mail:hover .tooltip,
.wrapper .mail:hover .tooltip:before {
  background: #88074c;
}

/* LINK */
.link {
  position: relative;
  text-decoration: none;
  display: inline-block;
  color: inherit;
  padding: 0 1px;
  transition: color ease 0.3s;
}

.link:after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 6%;
  min-height: 2px;
  left: 0;
  bottom: 0;
  background-color: #222;
  transition: all ease 0.3s;
}
</style>

<script></script>
